import { CSSProperties } from "@mui/styles";
import { getName, OntologyType } from "core/model/utils/ontologies";
import { useGetOntologies } from "core/model/utils/ontologies/hooks";
import { RadioOption } from "core/types";
import RadioGroup from "ds_legacy/components/RadioGroup";
import RadioGroupV2, {
  RadioGroupV2Props,
} from "ds_legacy/components/RadioGroupV2";
import { indexOf, sortBy } from "lodash";
import { useHideComponent } from "react-forms-state";
import { useTranslations } from "translations";

type OntologyRadioGroupProps = {
  boldLabel?: boolean;
  elementName: string;
  excludeOptions?: (number | string)[];
  includeOptions?: (number | string)[];
  label?: string;
  labelStyle?: CSSProperties;
  margin?: string;
  nullOption?: string;
  required?: boolean;
  sort?: (obj: RadioOption) => ReturnType<typeof indexOf>;
  type: OntologyType;
};

function useOptions({
  excludeOptions,
  includeOptions,
  nullOption,
  sort,
  type,
}: {
  excludeOptions?: (number | string)[];
  includeOptions?: (number | string)[];
  nullOption?: string;
  sort?: OntologyRadioGroupProps["sort"];
  type: OntologyType;
}) {
  const getOntologies = useGetOntologies();
  let options = getOntologies({ type }).map((ontology) => ({
    label: ontology.name,
    value: ontology.value,
    id: ontology.value as number | string,
  }));

  if (excludeOptions) {
    options = options.filter(
      (option: RadioOption) =>
        excludeOptions && !excludeOptions.includes(option.id),
    );
  }

  if (includeOptions) {
    options = options.filter(
      (option: RadioOption) => includeOptions?.includes(option.id),
    );
  }

  if (nullOption) {
    options.push({
      label: nullOption,
      value: -1,
      id: "default" as ToType,
    });
  }
  if (sort) {
    options = sortBy<RadioOption>(options, sort);
  }
  return options;
}

export function OntologyRadioGroupV2({
  elementName,
  excludeOptions,
  includeOptions,
  label,
  nullOption,
  sort,
  type,
  ...props
}: Omit<RadioGroupV2Props, "options" | "label"> &
  Pick<
    OntologyRadioGroupProps,
    | "sort"
    | "type"
    | "includeOptions"
    | "excludeOptions"
    | "nullOption"
    | "label"
  >) {
  const translations = useTranslations();
  const hideComponent = useHideComponent(elementName);
  const options = useOptions({
    type,
    excludeOptions,
    includeOptions,
    nullOption,
    sort,
  });

  if (hideComponent) return null;

  return (
    <RadioGroupV2
      label={label ? label : getName(type, translations)}
      options={options}
      elementName={elementName}
      {...props}
    />
  );
}

export default function OntologyRadioGroup({
  boldLabel,
  elementName,
  excludeOptions,
  includeOptions,
  label,
  labelStyle,
  margin,
  nullOption,
  required,
  sort,
  type,
}: OntologyRadioGroupProps) {
  const translations = useTranslations();
  const hideComponent = useHideComponent(elementName);
  const options = useOptions({
    type,
    excludeOptions,
    includeOptions,
    nullOption,
    sort,
  });

  if (hideComponent) return null;

  return (
    <RadioGroup
      marginWrapper={margin}
      elementName={elementName}
      label={label != null ? label : getName(type, translations)}
      options={options}
      bold={boldLabel}
      required={required}
      labelStyle={labelStyle}
    />
  );
}
