import {
  CANDIDATES_STATUS_SUCCESS,
  PATIENT_TAB_FILES,
  PATIENT_TAB_MAP,
  PATIENT_TAB_PROFILE,
  PATIENT_TAB_REHAB_FORMS,
  PATIENT_TAB_SEARCH,
  PATIENT_TAB_TIMELINE,
  PATIENT_TAB_TRANSITIONAL_CARE,
} from "core/consts";
import { isFilesActivatedAtAuctionLevel } from "core/model/auctions";
import { Auction } from "core/types";
import { ACCENT_COLOR } from "ds_legacy/materials/colors";
import { areNewRehabFormsActive } from "dsl/ecosystems/PatientForms/RehabForms/utils";
import { getTransitionalCareAction } from "dsl/ecosystems/PatientForms/TransitionalCare";
import { PRODUCT_TOURS, tourAttributes } from "dsl/molecules/useProductTour";
import { useTranslations } from "translations";
import { PatientTabConfig } from ".";

export function useGetPatientTabConfigs({
  auction,
}: {
  auction: Auction;
}): PatientTabConfig[] {
  const translations = useTranslations();

  const transitionalCareAction = getTransitionalCareAction(auction);

  return [
    {
      value: PATIENT_TAB_PROFILE,
      label: translations.patient.patientProfile,
    },
    {
      value: PATIENT_TAB_SEARCH,
      label: translations.search.search,
      disabled: auction?.candidates_status !== CANDIDATES_STATUS_SUCCESS,
      tour: tourAttributes({
        tourKey: PRODUCT_TOURS.search_merge_not_started.key,
        stepKey: PRODUCT_TOURS.search_merge_not_started.steps.search_tab.key,
      }),
    },
    {
      value: PATIENT_TAB_TIMELINE,
      label: translations.timeline.title,
      tour: tourAttributes({
        tourKey: PRODUCT_TOURS.files_page.key,
        stepKey: PRODUCT_TOURS.files_page.steps.documentation_tab.key,
      }),
    },
    {
      value: PATIENT_TAB_TRANSITIONAL_CARE,
      label: translations.patientForms.tabTitle,
      hide: !transitionalCareAction,
    },
    {
      value: PATIENT_TAB_REHAB_FORMS,
      label: translations.insuranceApp.eligibility,
      hide: !areNewRehabFormsActive(auction),
    },
    {
      value: PATIENT_TAB_FILES,
      label: translations.fileSection.tabTitle,
      hide: !isFilesActivatedAtAuctionLevel(auction),
      tour: tourAttributes({
        tourKey: PRODUCT_TOURS.files_page.key,
        stepKey: PRODUCT_TOURS.files_page.steps.files_tab.key,
      }),
    },

    {
      value: PATIENT_TAB_MAP,
      label: translations.patient.map.title,
    },
  ];
}

export const getDotProps = ({
  color,
  disabled,
  value,
}: {
  color: string | null;
  disabled?: PatientTabConfig["disabled"];
  value: PatientTabConfig["value"];
}) => {
  const isSearchStep = value === PATIENT_TAB_SEARCH;

  const isAccentColor = color === ACCENT_COLOR;

  const showDot = !disabled && isSearchStep && isAccentColor;

  return {
    showDot,
    dotColor: color,
  };
};
