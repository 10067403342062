import {
  Dropdown as NextUIDropdown,
  DropdownMenu as NextUIDropdownMenu,
  DropdownItem as NextUIDropdownMenuItem,
  DropdownItemProps as NextUIDropdownMenuItemProps,
  DropdownSection as NextUIDropdownSection,
  DropdownTrigger as NextUIDropdownTrigger,
} from "@nextui-org/react";
import clsx from "clsx";
import { Button } from "ds/ui";
import React, { ReactElement } from "react";

export type DropdownProps = {
  DropdownTrigger: ReactElement<typeof Button>;
  ariaLabel: string;
  sections: Array<DropdownSectionProps>;
};

type ExtendedDropdownItemProps = React.ComponentProps<
  typeof NextUIDropdownMenuItem
>;

type Color = Extract<
  ExtendedDropdownItemProps["color"],
  "success" | "primary" | "danger"
>;

export type DropdownMenuItemProps = Omit<
  ExtendedDropdownItemProps,
  "variant"
> & {
  color: Color;
};

type ExtendedDropdownSectionProps = React.ComponentProps<
  typeof NextUIDropdownSection
>;

type DropdownSectionProps = Omit<
  ExtendedDropdownSectionProps,
  "children" | "items"
> & {
  items: Array<NextUIDropdownMenuItemProps & { show: boolean }>;
  show: boolean;
};

export const Dropdown = ({
  ariaLabel,
  DropdownTrigger,
  sections,
}: DropdownProps) => {
  const dropdownSections = sections.filter(
    (section) =>
      section.show === true && section.items.some((item) => item.show === true),
  );
  return (
    <NextUIDropdown>
      <NextUIDropdownTrigger className="aria-expanded:scale-1">
        {DropdownTrigger}
      </NextUIDropdownTrigger>
      <NextUIDropdownMenu
        aria-label={ariaLabel}
        disallowEmptySelection
        classNames={{ base: "w-full" }}
      >
        {dropdownSections.map((section, sectionIndex) => {
          const dropdownItems = section.items.filter(
            (item) => item.show === true,
          );

          return (
            <NextUIDropdownSection
              key={sectionIndex}
              title={section.title}
              showDivider={section.showDivider}
              classNames={{ group: "flex flex-col gap-0.5" }}
            >
              {dropdownItems.map((item: NextUIDropdownMenuItemProps, index) => {
                return (
                  <NextUIDropdownMenuItem
                    startContent={item.startContent}
                    endContent={item.endContent}
                    key={index}
                    className={clsx(
                      "[&>svg]:!w-4",
                      "group !outline-none-v3 rounded-lg !bg-white p-2 data-[focus=true]:!ring-1 data-[hover=true]:!ring-1",
                      "data-[focus=true]:!outline data-[focus=true]:!outline-1 data-[focus=true]:!outline-offset-1",
                      "data-[hover=true]:!outline data-[hover=true]:!outline-1 data-[hover=true]:!outline-offset-1",
                      {
                        "data-[focus=true]:!bg-primary-light data-[hover=true]:!bg-primary-light data-[focus=true]:!text-primary-light-foreground data-[hover=true]:!text-primary-light-foreground data-[focus=true]:ring-primary data-[hover=true]:ring-primary":
                          item.color === "primary",
                        "data-[focus=true]:!bg-secondary-light data-[hover=true]:!bg-secondary-light data-[focus=true]:!text-secondary-light-foreground data-[hover=true]:!text-secondary-light-foreground data-[focus=true]:ring-secondary-dark data-[hover=true]:ring-secondary-dark":
                          item.color === "secondary",
                        "data-[focus=true]:!bg-warning-light data-[hover=true]:!bg-warning-light data-[focus=true]:!text-warning-light-foreground data-[hover=true]:!text-warning-light-foreground data-[focus=true]:ring-warning data-[hover=true]:ring-warning":
                          item.color === "warning",
                        "text-danger data-[focus=true]:!bg-danger-light data-[hover=true]:!bg-danger-light data-[focus=true]:!text-danger-dark data-[hover=true]:!text-danger-dark data-[focus=true]:ring-danger data-[hover=true]:ring-danger":
                          item.color === "danger",
                        "text-success data-[focus=true]:!bg-success-light data-[hover=true]:!bg-success-light data-[focus=true]:!text-success-dark data-[hover=true]:!text-success-dark data-[focus=true]:ring-success data-[hover=true]:ring-success":
                          item.color === "success",
                      },
                      item.className,
                    )}
                    {...item}
                  >
                    {item.title}
                  </NextUIDropdownMenuItem>
                );
              })}
            </NextUIDropdownSection>
          );
        })}
      </NextUIDropdownMenu>
    </NextUIDropdown>
  );
};

Dropdown.displayName = "RecareUI.Dropdown";
