import { TRACK_EVENTS } from "core/consts";
import { isRecareAccount } from "core/model/accounts";
import { hasCrypto } from "core/model/crypto";
import {
  generateSessionKeys as generateAESKeys,
  getSessionAccess,
} from "core/model/crypto/cryptoService";
import {
  Account,
  AnyObject,
  AuctionRequest,
  SessionKey,
  TrackEventFn,
} from "core/types";
import { TrackingEventName } from "core/validationSchemas/tracking";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTracking } from "react-tracking";

export const NO_ACCOUNT = "no account";
export const NO_ACCOUNTS = "no accounts";
export const NO_PRIVATE_KEY = "no private key";
export const NO_CRYPTO = "no crypto";
export const ACCOUNT_UNAUTHORIZED = "account not authorized";
export const NO_SESSION_KEY = "no session key";
export const REQUEST_PENDING = "request pending";

export function isEncryptionAvailableForUser(
  accounts: Array<Account> | null | undefined,
  account: Account | null | undefined,
  privateKey: any,
  auctionRequest: AuctionRequest | null,
  isSealdOnly: boolean = false,
): [boolean, string | null] {
  if (!isSealdOnly) {
    // check should not be required except if backend does not send what it should
    if (!account) {
      return [false, NO_ACCOUNT];
    }

    if (!accounts || accounts.length == 0) {
      return [false, NO_ACCOUNTS];
    }
    // Messenger is activated starting here.
    if (!privateKey || privateKey.length === 0) {
      return [false, NO_PRIVATE_KEY];
    }

    if (auctionRequest?.access_request_pending && !auctionRequest.session_key) {
      return [false, REQUEST_PENDING];
    }

    if (auctionRequest?.has_session_keys && !auctionRequest.session_key) {
      return [false, NO_SESSION_KEY];
    }
    if (!accounts.some((a) => account && a.id == account.id)) {
      return [false, ACCOUNT_UNAUTHORIZED];
    }
  }

  if (!hasCrypto()) {
    return [false, NO_CRYPTO];
  }

  return [true, null];
}

/** @deprecated To be removed when old encrytion gets decommissioned */
export async function createSessionKeys(
  accounts: Array<Account>,
  context: AnyObject,
): Promise<Array<SessionKey>> {
  // @ts-ignore
  const keys = await generateAESKeys(accounts);
  return keys.map(
    (element: { account_id: number; algorithm: string; session_key: string }) =>
      ({
        account_id: element.account_id,
        ...context,
        session_key: element.session_key,
        algorithm: element.algorithm,
      }) as SessionKey,
  );
}

/** @deprecated To be removed when old encrytion gets decommissioned */
export async function generateSessionKeys(
  trackEvent: TrackEventFn,
  sessionKey: any,
  accounts: Array<Account> | null,
  context: AnyObject,
  account: Account | null | undefined,
  updateSessionKey: (s: Array<SessionKey>) => Promise<void> | void,
) {
  if (sessionKey != null) return;

  if (!account) {
    trackEvent({
      system_event: true,
      name: TRACK_EVENTS.GENERATE_SESSION_KEY,
      status: "error",
      reason: NO_ACCOUNT,
      ...context,
    });

    return;
  }

  if (!accounts || accounts.length == 0) {
    console.log("%c No account supplied", "background: red; color: white");
    trackEvent({
      system_event: true,
      name: TRACK_EVENTS.GENERATE_SESSION_KEY,
      status: "error",
      reason: NO_ACCOUNTS,
      ...context,
    });

    return;
  }

  // This should never happen.
  if (!accounts.some((a) => account && a.id == account.id)) {
    console.log("%c Account not authorized", "background: red; color: white");
    trackEvent({
      system_event: true,
      name: TRACK_EVENTS.GENERATE_SESSION_KEY,
      status: "error",
      reason: ACCOUNT_UNAUTHORIZED,
      ...context,
    });

    return;
  }

  console.log("%c Generate session keys ", "background: orange; color: white");
  trackEvent({
    system_event: true,
    name: TRACK_EVENTS.GENERATE_SESSION_KEY,
    status: "success",
    ...context,
  });

  const keys = await createSessionKeys(accounts, context);

  const mySessionKey = keys.find(
    (k: SessionKey) => account && k.account_id === account.id,
  );

  if (!mySessionKey) {
    trackEvent({
      system_event: true,
      name: TRACK_EVENTS.SAVE_SESSION_KEYS,
      status: "error",
      reason: "Account not found",
      ...context,
    });

    return;
  }

  trackEvent({
    system_event: true,
    name: TRACK_EVENTS.SAVE_SESSION_KEYS,
    status: "success",
    ...context,
  });

  await updateSessionKey(keys);
  return keys;
}

export type UseSessionKeyHook = {
  decryptedSessionKey: CryptoKey | null;
  done: boolean;
  sessionKeys: Array<SessionKey> | null;
};

function trackError(
  name: TrackingEventName,
  context: AnyObject,
  trackEvent: TrackEventFn,
) {
  trackEvent({ name, ...context });
  console.error(name, context);
}

export function getAccounts(
  socialWorkers: Array<Account> | null | undefined,
  account: Account | null | undefined,
): Array<Account> {
  if (socialWorkers == null) return [];
  if (!account) return socialWorkers;

  const newAccounts: Array<Account> = [...socialWorkers];

  if (!socialWorkers.find((s) => s.id === account.id))
    newAccounts.push(account);

  return (
    newAccounts.filter(
      (a) => a.public_key != null && a.public_key.length > 0,
    ) || []
  );
}

/** @deprecated To be removed when old encrytion gets decommissioned */
export function useGenerateSessionKeysDecryptKey({
  accounts,
  account,
  privateKey,
  trackingEvent,
  trackingContext = {},
  encryptionContext = {},
  skip,
  keys,
  flag = true,
}: {
  account: Account | null | undefined;
  accounts: Array<Account>;
  encryptionContext?: AnyObject;
  flag?: boolean;
  keys?: Array<SessionKey> | null;
  privateKey: string | null;
  skip?: boolean;
  trackingContext?: AnyObject;
  trackingEvent: TrackingEventName;
}): UseSessionKeyHook {
  const { trackEvent } = useTracking();

  const decryptingRef = useRef<boolean>(false);
  const [done, setDone] = useState(skip || false);
  const [sessionKeys, setSessionKeys] = useState(keys != null ? keys : null);
  const [decryptedSessionKey, setDecryptedSessionKey] =
    useState<CryptoKey | null>(null);

  const checkUserEncryption = useCallback(
    () => isEncryptionAvailableForUser(accounts, account, privateKey, null),
    [accounts, account, privateKey, trackingContext],
  );

  useEffect(() => {
    if (sessionKeys != null || skip) return;

    const [userSupportsEncryption, reason] = checkUserEncryption();

    if (userSupportsEncryption) {
      generateSessionKeys(
        trackEvent,
        null,
        accounts,
        encryptionContext,
        account,
        (k: any) => setSessionKeys(k),
      );
    } else {
      setDone(true);

      if (!isRecareAccount(account))
        trackError(
          trackingEvent,
          {
            ...trackingContext,
            status: "error",
            reason,
            accounts: JSON.stringify(accounts.map((s) => s.id)),
            account_id: account?.id,
          },
          trackEvent,
        );
    }
  }, [skip, checkUserEncryption]);

  useEffect(() => {
    async function decryptSessionKey() {
      decryptingRef.current = true;

      const sessionKey = sessionKeys?.find(
        ({ account_id }) => account_id === account?.id,
      );

      if (sessionKey != null) {
        const decrypted = await getSessionAccess({
          context: {},
          privateKey: privateKey as string,
          sessionKey,
          trackEvent,
        });
        if (decrypted != null) setDecryptedSessionKey(decrypted);
        setDone(true);
      } else setDone(true);

      decryptingRef.current = false;
    }

    if (sessionKeys != null && !done && decryptingRef.current === false) {
      const [encryptionAvailable] = checkUserEncryption();
      if (flag === true && encryptionAvailable) decryptSessionKey();
      else setDone(true);
    }
  }, [sessionKeys, done, checkUserEncryption]);

  useEffect(() => {
    if (skip) setDone(true);
  }, [skip]);

  return {
    sessionKeys,
    decryptedSessionKey,
    done,
  };
}

/** @deprecated To be removed when old encrytion gets decommissioned */
export async function fetchKeysdecryptKey({
  context,
  privateKey,
  sessionKey,
  setAccessDenied,
  setDecryptedSessionKey,
  trackEvent,
}: {
  context: AnyObject;
  privateKey: string | undefined;
  sessionKey: SessionKey | null;
  setAccessDenied: (b: boolean) => void;
  setDecryptedSessionKey: (s: any) => void;
  trackEvent: TrackEventFn;
}) {
  const decryptedSessionKey = await getSessionAccess({
    context,
    privateKey,
    sessionKey,
    trackEvent,
  });

  if (decryptedSessionKey === null) setAccessDenied(true);
  else if (decryptedSessionKey != null) {
    setDecryptedSessionKey(decryptedSessionKey);
  }
}
