import IconButton from "@mui/material/IconButton";
import { TRACK_EVENTS } from "core/consts";
import { activatePrintE2ETesting } from "core/model/utils/featureFlags";
import { RSFAB } from "ds_legacy/components/RSButton";
import Tooltip from "ds_legacy/components/Tooltip";
import { ICON_DARK } from "ds_legacy/materials/colors";
import { sizing } from "ds_legacy/materials/metrics";
import { usePrint } from "dsl/atoms/Contexts";
import { PrinterIcon } from "lucide-react";
import { useEffect, useLayoutEffect } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";

export function openPrintPage(windowInstance: Window) {
  const url = new URL(windowInstance.location.href);
  url.searchParams.set("print", "");
  windowInstance.open(
    url.toString(),
    activatePrintE2ETesting() ? "_self" : "_blank",
  );
}

export const usePrintWindow = (skip?: boolean) => {
  const print = usePrint();

  const defer = (fn: () => void) => setTimeout(fn, 2000);

  useEffect(() => {
    if (skip || !print || activatePrintE2ETesting()) return;

    defer(() => {
      window.print();
      defer(() => window.close());
    });
  }, [print, skip]);
};

export const usePreventPrintMenu = () => {
  const { trackEvent } = useTracking();

  useLayoutEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.keyCode == 80) {
        e.preventDefault();
        trackEvent({ name: TRACK_EVENTS.PRINT_MENU });

        openPrintPage(window);
        return false;
      }
      return true;
    };
    window.addEventListener("keydown", keydown);

    return () => window.removeEventListener("keydown", keydown);
  }, []);
};

export default function PrintButton({ fab = false }: { fab?: boolean }) {
  const print = usePrint();
  const translations = useTranslations();
  const iconStyle = {
    color: ICON_DARK,
    width: sizing(2.5),
    height: sizing(2.5),
  };
  const { trackEvent } = useTracking();

  usePreventPrintMenu();

  if (print) return null;

  if (fab) {
    return (
      <RSFAB
        ariaLabel={translations.actions.print}
        onClick={() => {
          trackEvent({ name: TRACK_EVENTS.PRINT_BUTTON });
          openPrintPage(window);
        }}
        tooltip={translations.actions.print}
        color="white"
      >
        <PrinterIcon
          style={iconStyle}
          height={iconStyle.height}
          width={iconStyle.width}
        />
      </RSFAB>
    );
  }

  return (
    <IconButton
      aria-label={translations.actions.print}
      size="large"
      onClick={() => {
        trackEvent({ name: TRACK_EVENTS.PRINT_BUTTON });
        openPrintPage(window);
      }}
    >
      <Tooltip title={translations.actions.print}>
        <PrinterIcon
          style={iconStyle}
          height={iconStyle.height}
          width={iconStyle.width}
        />
      </Tooltip>
    </IconButton>
  );
}
